body.profile {

    background: $primary-white;

    .slick-dots {
        padding: 0 0 15px 0;
        margin: unset;
    }
    .slick-dots li {
        @include size(10px);
        margin: 0 5px;
    }
    .slick-dots li button {
        @include size(10px);
        background-color: $navy-03;
    }
    .slick-dots li button.active {
        background-color: $navy-00;
    }

    .sticky-header {
        //display: none;
        @include transform(translate(0, -100%));
        @include transition(all 0.3s);
        &.show {
            //display: block;
            @include transform(translate(0, 0));
        }
        &.passed {
            @include transform(translate(0, -100%));
        }
        @include position(fixed, $nav-height 0 null 0);
        @include until($nav-max) {
            @include position(fixed, $nav-height-small 0 null 0);
        }
        z-index: 1;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
        background-color: $navy-01;
        text-align: center;
        padding: 5px 0 ;
        .pet-header {
            color: white;
            @include transition(opacity 0.3s);
            &.transition {
                opacity: 0;
            }
            .roundal {
                @include size(50px);
                line-height: 50px;
                font-size: 2.5rem;
                margin-right: 10px;
            }
            .name {
                @include inalign(middle);
                text-transform: none;
                margin: 0;
                @include until($screen-tablet) {
                    font-size: $font-size-body;
                }
            }
        }
    }

    .profiles {
        width: 100%;
        overflow: hidden;
    }
    .profiles-wrapper {
        .action {
            cursor: pointer;
        }
        white-space: nowrap;
        &.slick-initialized {
            cursor: pointer;
            cursor: move;
            cursor: hand;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            outline: none;
            &.grabbing {
                cursor: grabbing;
                cursor: -moz-grabbing;
                cursor: -webkit-grabbing;
            }
        }
    }
    a {
        outline: none;
    }
    .profiles-wrapper[data-count="1"] {
        .next-pet, .prev-pet {
            display: none !important;
        }
    }

    .profile-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        white-space: normal;
        @include inalign(top);
        outline: none;
        .profile-top {
            position: relative;
            text-align: center;
            background-color: $primary-white;
            margin: 0 0 40px;
            padding: 30px 15px 0 15px;
        }
        .profile-bottom {
            position: relative;
            max-width: 704px;
            text-align: center;
            margin: 0 auto;
            padding: 15px 15px 30px 15px;
            &.has-notification {
                padding: 0 15px 30px 15px;
            }
        }
        .header-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            max-width: 704px;
            margin: 0 auto;
        }
        .header {
            font-size: 0;
            width: 100%;
            > * {
                @include inalign(middle);
            }
            h1 {
                font-size: $font-size-xl;
                font-family: $handwritten-font-family;
                line-height: 40px;
                width: calc(100% - 100px);
                padding: 20px 9px 0 9px;
                hyphens: auto;
                overflow-wrap: anywhere;
                &.single-pet{
                    @include until($screen-tablet) {
                        width: unset;
                        padding: 20px 0 0 0;
                    }
                }
            }
            .icon {
                position: relative;
                font-size: $font-size-m;
                color: $navy-00;
                background-color: $pastel-lighter-silver;
                border-radius: 40px;
                @include size(40px);
                &:before {
                    position: absolute;
                    top:50%;
                    left:50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
        }
        #alerts {
            padding: 15px;
            @include until($screen-tablet) {
                padding: 0 15px;
            }
        }
        .attributes-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 0 27px 0;
        }
        .attributes {
            max-width: 704px;
            width: 100%;
            flex-shrink: 0;
            border-radius: 12px;
            background: $primary-white;
            margin: 12px 0;
            padding: 17px 24px 16px 24px;
            border: 1px solid $primary-blueberry-30;
            @include until($screen-tablet) {
                padding: 20px 16px 16px 16px;
            }
            .header {
                width: 100%;
                @include flexbox(row, center, space-between);
                h2 {
                    font-family: $default-font-family;
                    font-size: $font-size-s;
                    font-weight: 700;
                    line-height: 26px;
                    color: $primary-colour;
                }
            }
            hr {
                border-top: 0 solid $primary-blueberry-10;
                margin: 10px 0 12px 0;
            }
            .attribute {
                @include flexbox(row, center, space-between);
                overflow: hidden;
                text-align: left;
                padding: 4px 0;
                @include until($screen-tablet) {
                    @include flexbox(row, end, space-between);
                }
                .text-container {
                    display: flex;
                    flex-direction: column;
                    padding-right: 21px;
                }
                .name {
                    display: flex;
                    min-width: 300px;
                    max-width: 400px;
                    color: $navy-00;
                    font-size: $font-size-xs;
                    font-weight: 400;
                    line-height: 24px;
                    @include until($screen-tablet) {
                        min-width: 100%;
                    }
                }
                .text {
                    width: 100%;
                    color: $navy-00;
                    font-weight: 400;
                    line-height: 24px;
                    @include flexbox(row, center, space-between);
                    @include until($screen-tablet) {
                        @include flexbox(column, flex-start, flex-start);
                    }
                }
                .link {
                    padding: 10px 0;
                    @include until($screen-tablet) {
                        align-self: center;
                    }
                }
            }
        }
        .action-tile-container .action-tile-img-container img{
            @include at-least($screen-tablet) {
                transform: scale(1.3);
                object-position: -32px;
            }
        }
    }
}

.pet-lifestyle {
    .checklist span > span.description {
        display: block;
        font-size: $font-size-s;
    }
}

.raf-widget-wrapper {
    padding: 0 15px 30px 15px;
    position: relative;
    max-width: 704px;
    text-align: center;
    margin: 0 auto;
    padding: 15px 15px 30px 15px;
}

.raf-widget-v2 {
    position: relative;
    max-width: 704px;
    text-align: center;
    margin: -30px auto 20px;
    background-color: var(--warn-background);
    padding: 10px 10px 20px 0px;

    @include at-least($screen-desktop) {
        border-radius: 12px;
    }

    .container {
        width: 100%;
        max-width: 640px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 10px 0px 0px 0px;
        flex-direction: column;

        @include at-least($screen-desktop) {
            flex-direction: row;
        }

        h1 {
            font-family: $handwritten-font-family;
            font-size: $font-size-xl;
            text-align: center;
            margin: 5px 3px -3px 10px;

            @include at-least($screen-desktop) {
                padding: 0px;
            }
        }
        a {
            font-size: 16px;
            color: $primary-colour;
            height: 40px;
            border-radius: 16px;

            @include at-least($screen-desktop) {
                width: auto;
            }
        }

        .btn-secondary {
            margin: 20px 0px 4px 0px;
            min-width: 250px;

            @include at-least($screen-desktop) {
                min-width: initial;
            }
        }

        .dog-widget-img {
            width: 120px;
        }

        .dog-and-text {
            display: flex;
            width: 100%;
            align-items: stretch;
            justify-content: space-around;

            @include at-least($screen-desktop) {
                width: auto;
            }

            .discount-box {
                background: #fbc33e;
                padding: 4px 2px 2px 2px;
                color: black;
                font-weight: 900;
                text-decoration-thickness: 0px;
                text-decoration: none;
                position: absolute;
                top: -10px;
                left: 50px;
                width: 40px;
            }

            .raf-v4-dog-widget-v2 {
                position: relative;
                bottom: 33px;
                width: 120px;

                .wrapper {
                    width: 120px;
                }
            }
        }
    }
}
