body.account.feeding-plan {
    @include background-image($signup-background);
    background-attachment: fixed;

    .notification {
        max-width: 550px;
    }
    .main {
        margin-top: 0;
    }

    .top-block {
        text-align:center;
        margin-top: 30px;

        .header-block {

            .heading {
                padding: 0 30px 15px 30px;
            }

            .secondary-heading {
                padding: 0px;
                @include at-least(340px) {
                    padding: 0px 30px;
                }
                margin-bottom: 10px;
                margin-top: 80px;

                .roundal-image {
                    margin-bottom: 20px;
                    .pet-roundal {
                        margin-top: -75px;
                    }
                }
                a.profile-link {
                    font-size: $font-size-m;
                }
                p {
                    margin-top: 20px;
                }
                .which-order {
                    margin: 30px auto;
                    width: 100%;
                    @include at-least($screen-phone) {
                        width: 400px;
                    }
                }
            }
        }

        form {
            .input-wrapper {
                width: 280px;

                .down-arrow {
                    height: 100%;
                    margin-top: 10px;
                }
            }
        }

        .emarsys-custom-component-feeding-plan-1 {
            &:empty {
                display: none;
            }

            .card {
                position: relative;
                background-color: $pastel-lighter-ocean-mist;
                overflow: hidden;
                display: inline-block;
                width: 296px;
                margin: 16px auto 0 auto;
                padding: 16px 61px 16px 16px;
                border-radius: 9px;
                text-align: left;
                @include at-least($screen-tablet) {
                    width: 380px;
                }


                &:before {
                    position: absolute;
                    top: 0;
                    right: 0;
                    content: "";
                    background: url("/static/images/svgs/vegetables-illustration.svg") no-repeat right;
                    background-size: contain;
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                }

                .text {

                }

                .cta-container {

                }

                .cta {
                    color: $primary-colour;
                    font-weight: 600;
                    line-height: 24px;
                    text-decoration: none;
                    position: relative;
                    bottom: -8px;

                    &:focus-visible {
                        box-shadow: none;
                    }
                }
            }
            .ellipse {
                background-color: $pastel-darker-ocean-mist;
                width: 360px;
                height: 300px;
                border-radius: 50%;
            }

            .ellipse-container {
                height: 100px;
                overflow: hidden;
                position: absolute;
                left: -120px;
                bottom: -60px;
            }
        }
    }

    .content {
        padding: 20px;
        min-height: 0;
        margin-bottom: 10px;

        .container {
            max-width: 500px;
            padding-left: 5px;
            padding-right: 5px;

            > h2 {
                margin-bottom: 30px;
            }

            .cta-restart {
                margin-top: 0;
                margin-bottom: 40px;
            }

        }

        @include at-least($screen-tablet) {
            .container {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        @include until($screen-phone) {
            .container {
                margin-top: -10px;
                margin-bottom: -10px;
            }
        }

        &.header {
            text-align: center;
            color: $navy-00;
            padding: 50px 15px;

            .next-delivery-date {
                @include inalign(middle);
            }

            .btn {
                margin-top: 20px;
            }
        }

        &.breakdown {
            text-align: center;


            .daily-calories {
                padding: 15px 0;
                font-size: $font-size-m;
                margin-bottom: 35px;

                label {
                    font-weight: normal;
                    font-size: $font-size-m;
                }
                .pill {
                    white-space: nowrap;
                }
            }

            .nutribar {
                .key {
                    list-style: none;
                    display: table;
                    padding: 0;
                    margin: 0;
                    padding-bottom: 10px;
                    width: 100%;
                    font-size: $font-size-xs;
                    @include at-least($screen-desktop) {
                        font-size: $font-size-m;
                    }

                    li {
                        display: table-cell;
                        text-align: center;
                        width: 33.3333%;

                        &:before {
                            @include inalign(middle);
                            content: "";
                            @include size(15px);
                            border-radius: 50%;
                            margin-right: 5px;
                            vertical-align: middle;
                        }

                        &.dry {
                            color: $navy-00;

                            &:before {
                                background-color: $food-dry;
                            }
                        }

                        &.wet {
                            color: $navy-00;

                            &:before {
                                background-color: $food-wet;
                            }
                        }

                        &.other {
                            color: $navy-00;

                            &:before {
                                background-color: $food-other;
                            }
                        }
                    }
                }

                .bar {
                    @include flexbox(row, center, center);
                    border-radius: 10px;
                    overflow: hidden;
                    padding: 0;
                    list-style: none;
                    margin: 0;
                    line-height: 1.5;
                    @include at-least($screen-desktop) {
                        line-height: 2;
                    }

                    .group {
                        color: $navy-00;
                        @include padding(10px null);
                        min-width: 40px;
                        text-align: center;
                        font-size: $font-size-s;
                        @include at-least($screen-desktop) {
                            font-size: $font-size-m;
                        }

                        &.dry {
                            background-color: $food-dry;
                        }

                        &.wet {
                            background-color: $food-wet;
                        }

                        &.other {
                            background-color: $food-other;
                        }
                    }
                }
            }

            input[type=radio] {
                display: none;
            }

            #toggle-percentage:checked {
                ~ .toggle-buttons label[for=toggle-percentage] {
                    @include toggle_on;
                }

                ~ .nutribar {
                    .group:before {
                        content: attr(data-percent);
                    }
                }
            }

            #toggle-calories:checked {
                ~ .toggle-buttons label[for=toggle-calories] {
                    @include toggle_on;
                }

                ~ .nutribar {
                    .group:before {
                        content: attr(data-kcal);
                    }
                }
            }

            .toggle-buttons {
                margin-top: 30px;
                margin-bottom: 20px;

                label {
                    line-height: 35px;
                    font-size: $font-size-body;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    @include at-least($screen-desktop) {
                        line-height: 65px;
                        font-size: $font-size-m;
                    }
                }
            }

            .alert.edit-portions {
                background-color: $pastel-lighter-frost;

                .icon {
                    float: left;
                    font-size: 3.2rem;

                    &:before {
                        @include icon($icon-question-circle);
                        color: $secondary-03;
                    }
                }
            }
        }

        &.portions {
            text-align: center;
            h3 {
                margin-bottom: 20px;
            }
            .portion {
                position: relative;
                min-height: 100px;
                background-color: white;
                overflow: hidden;
                text-align: center;
                border-radius: 4px;
                margin-bottom: 15px;
                .icon {
                    @include position(absolute, 0 null 0 0);
                    width: 80px;
                    text-align: center;
                    padding: 15px;
                    background-color: $navy-01;
                    font-size: 50px;
                    white-space: nowrap;
                    &:before {
                        color: white;
                        vertical-align: middle;
                    }
                    &:after {
                        content: "";
                        width: 1px;
                        margin-left: -5px;
                        height: 100%;
                        @include inalign(middle);
                    }
                }
                .info {
                    padding: 15px;
                    padding-left: 80px;
                    .details {
                        @include flexbox(row, center);
                        @include flex-wrap(wrap);
                        min-height: 80px;
                        .title {
                            font-weight: bold;
                            padding-bottom: 5px;
                            width: 100%;
                            white-space: normal;
                            padding-left: 15px;
                        }

                        .big {
                            font-size: 3.2rem;
                            font-weight: bold;
                        }
                        .left, .right {
                            width: 50%;
                            padding: 5px;
                            > * {
                                vertical-align: middle;
                            }
                        }
                        .center {
                            width: 100%;
                            padding: 5px;
                        }

                        @include at-least($screen-tablet) {
                            @include flex-wrap(nowrap);
                            .title {
                                text-align: left;
                                padding-left: 15px;
                            }
                        }
                    }
                }

                &.dry {
                    .icon {
                        background-color: $navy-01;
                    }
                    .icon:before {
                        @include icon($icon-scoop-circle);
                    }
                    .details {
                        .right {
                            border-left: 3px solid $navy-01;
                        }
                    }
                }
                &.wet {
                    .icon {
                        background-color: $secondary-01;
                    }
                    .icon:before {
                        @include icon($icon-wet-tray-circle);
                    }
                    .details {
                    }
                }
                &.other {
                    .icon {
                        background-color: $secondary-03;
                    }
                    .icon:before {
                        @include icon($icon-dental-circle);
                    }
                }
                &.dental-dailies {
                    .icon {
                        background-color: $pastel-lighter-frost;
                    }
                    .icon:before {
                        @include icon($icon-dental-circle);
                    }
                }
            }
        }

        &.products {
            background-color: $pastel-lighter-frost;

            .container {
                @include at-least($screen-desktop) {
                    @include grid-blocks(12);
                }
            }

            .dry {
                overflow: hidden;

                .blend {
                    text-align: center;
                    @include at-least($screen-phone) {
                        width: 500px;
                        margin: auto;
                    }

                    .kibbles {
                        @include grid-blocks(3);
                        padding: 0;
                        list-style: none;
                        margin: 30px auto;
                        text-align: left;

                        .kibble {
                            padding-bottom: 15px;

                            &:nth-child(even) {
                                .wrapper {
                                    @include justify-content(flex-start);

                                    img {
                                        @include rotate(45);
                                        width: 60px;
                                    }

                                    .info {
                                        @include order(1);
                                        text-align: left;
                                        padding-right: 0;
                                        padding-left: 5px;
                                    }
                                }
                            }

                            .wrapper {
                                display: inline-block;
                                @include flexbox(row, center, flex-end);

                                img {
                                    width: 60px;
                                    @include rotate(-45);
                                }

                                .info {
                                    text-align: right;
                                    padding-right: 5px;

                                    .percentage {
                                        font-size: $font-size-m;
                                        font-weight: bold;
                                    }

                                    .name {
                                        font-size: $font-size-s;
                                    }
                                }
                            }
                        }
                    }

                    .bag {
                        display: inline-block;
                        width: 250px;
                        height: 280px;
                        background-size: cover;
                        background-position: center top;
                        @include at-least($screen-desktop) {

                            margin-bottom: -4px;
                        }
                    }
                }
                .raf-button {
                    margin: 20px 0;
                    @include flexbox(row, center, center);
                    a   {
                        width: 250px;
                    }
                }
                .ingredients {
                    position: relative;
                    border: none;

                    .accordion-item {
                        border-bottom: none;
                    }

                    .accordion-item-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        background-color: $navy-00;
                        color: white;
                        border-bottom: none;
                        max-width: 325px;
                        margin: auto;
                        padding: 15px;

                        .toggle {
                            &:after {
                                @include icon($icon-chevron-down);
                                pointer-events: none;
                            }
                            color: white;
                        }

                        .title {
                            font-size: $font-size-body;
                        }

                        @include at-least($screen-desktop) {
                            text-align: center;
                            .title {
                                width: auto;
                                padding-right: 10px;
                                font-size: $font-size-body;
                            }
                        }
                    }

                    .accordion-item-body {
                        padding:0;
                        border: none;
                        text-align: center;
                        @include flexbox(column, center, center);

                        @include at-least($screen-desktop) {
                            @include flexbox(row, flex-start, space-between);
                        }

                        > * {
                            width: 100%;
                            margin: 0;
                            padding: 15px;

                            h4 {
                                margin-bottom: 15px;
                                font-weight: 600;
                            }
                        }
                    }

                    #show-or-hide {
                        display: none;

                        & ~ .show-less {
                            display: none;
                        }

                        &:checked {
                            & ~ table tr {
                                display: table-row;
                            }

                            & ~ .show-more .icon {
                                @include transform(rotate(180deg));
                            }

                            & ~ .show-more {
                                display: none;
                            }

                            & ~ .show-less {
                                display: inline-block;
                            }
                        }
                    }

                    .constituents {
                        list-style: none;
                        padding: 60px 0 0 50px;
                        margin: auto;

                        li {
                            text-align: left;
                            display: inline-block;
                            vertical-align: top;
                            margin-left: -40px;
                            margin-top: -60px;

                            &:nth-child(even) {
                                text-align: right;
                                margin-top: 0;
                                margin-left: -40px;
                            }
                        }

                        .hex {
                            position: relative;
                            display: inline-block;
                            font-weight: bold;
                            color: white;
                            height: 120px;
                            width: 140px;
                            background-position: top left;
                            background-size: contain;
                            background-repeat: no-repeat;
                            text-align: center;

                            @include until($screen-tablet) {
                                &.fibre {
                                    margin-top: 61px;
                                }
                            }

                            &.protein:before {
                                color: $primary-blueberry-10;
                            }

                            &.fat:before {
                                color: $primary-blueberry-light-10;
                            }

                            &.fibre:before {
                                color: $primary-blueberry-30;
                            }

                            &.ash:before {
                                color: $primary-blueberry-light-30;
                            }

                            &:before {
                                height: 100%;
                                @include inalign(middle);
                                content: "\2B22";
                                display: block;
                                font-size: 200px;
                                transform: rotate(-30deg);
                                position: absolute;
                                top: 25px;
                                left: 17px;
                                z-index: 0;
                            }

                            > span {
                                @include inalign(middle);
                                width: 80%;
                                line-height: 1.4;
                                color: $navy-00;
                                position: relative;
                                top: 37px;
                                left: 3px;
                            }

                            .ash-help {
                                @include position(absolute, 58px -35px 0 null);
                                cursor: pointer;
                                color: $navy-00;
                                font-size: $font-size-l;
                                line-height: 40px;

                                &:before {
                                    @include icon($icon-information);
                                }
                            }
                        }
                    }

                    #ash-tip-toggle {
                        display: none;

                        &:checked ~ .ash-tip {
                            display: block;
                        }
                    }

                    .ash-tip {
                        display: none;

                        h3 {
                            text-align: center;
                        }

                        text-align: left;
                        font-size: 1.5rem;
                        background: rgb(233, 242, 255);
                        padding: 30px;
                        border-radius: 4px;
                        margin-top: 30px;
                    }


                    .additives {
                        .table {
                            text-align: left;

                            tr:nth-child(4) ~ tr {
                                display: none;
                            }

                            td {
                                padding: 10px 15px;

                                &:nth-child(2) {
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .portion-block {
        background-color: $pastel-lighter-frost;
        margin-bottom: 10px;
        padding-bottom: 20px;

        .portions {
            @include at-least($screen-tablet) {
                width: 460px;
                margin: auto;
            }

            > h3 {
                text-align: center;
                padding: 15px 15px 0 15px;
            }

            .sub-heading {
                padding: 15px;
                font-size: $font-size-m;
                font-weight: bold;
            }

            .food-block {
                padding: 15px;
                display: flex;
                border-radius: 8px;
                background-color: $primary-white;
                @include at-least($screen-phone) {
                    padding:15px 10% 15px 5%;
                }

                .icons-info {
                    margin: auto 10px auto 0;
                }

                > span.circle {
                    line-height: 60px;
                    position: relative;

                    &:before {
                        font-weight: normal;
                        font-size: larger;
                    }

                    @include at-least($screen-phone) {
                        margin: 0 10px 0 0;
                    }

                    &.icon {
                        font-size: $font-size-l;
                    }
                }

                .icon-times {
                    margin: auto 10px;
                    font-size: $font-size-xs;

                    @include at-least($screen-phone) {
                        margin: auto 20px auto 10px;
                    }
                }

                .wet-grams {
                    margin: auto 15px;

                    @include at-least($screen-phone) {
                        margin: auto 0;
                    }
                }
                .strong {
                    font-weight: 800;
                }
                .dry-setting {
                    margin: 5px auto;
                }
            }
        }
    }
}

.raf-experiment {
    background-color: $product-warning-background;
    margin-top: -10px;
    margin-bottom: $spacing-m !important;
    
    @include at-least($screen-desktop) {
        border-radius: 12px;
    }
    

    .container {
        @include grid-blocks(6);
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        padding: 45px 0 35px 0;
        flex-direction: column;

        @include at-least($screen-desktop) {
            flex-direction: row;
        }

        .text {
            order: 2;
            text-align: center;
            margin-top: $spacing-l;

            @include at-least($screen-desktop) {
                order: 1;
                text-align: left;
                margin-top: 0;
            }

            h1 {
                font-family: $handwritten-font-family;
                font-size: $font-size-xl;
                padding: 0 3rem;
                text-align: center;
                margin-bottom: $spacing-xs;

                @include at-least($screen-desktop) {
                    padding: 0;
                }
            }

            a {
                font-size: 16px;
                color: $primary-colour;
            }
        }


        .widget-link {
            text-decoration: none;
            order: 1;
            width: auto;
            display: inline-block;
            margin: 0 auto;

            @include at-least($screen-desktop) {
                width: auto;
                order: 2;
                margin: 0;
            }

            .raf-v4-dog-widget {
                border-radius: 12px;
                width: 100%;


                .copy-box {
                    padding-top: $spacing-s;
                    padding-bottom: $spacing-s;
                    min-height: auto;
                    border-radius: 12px;

                    .copy {
                        text-decoration: none;
                    }

                    .discount-box {
                        text-align: center;
                        width: 60%;
                    }
                }
            }
        }
    }
}

.raf-wrapper {
    background-color: $pastel-lighter-frost;
    padding: $spacing-m $spacing-s !important;
    margin-top: -10px;

    @include at-least($screen-desktop) {
        padding: $spacing-m 0 !important;
    }

    .action-tile-container {
        max-width: 704px;

        .action-tile-img-container img{
            @include at-least($screen-tablet) {
                transform: scale(1.3);
                object-position: -32px;
            }
        }
    }
}

.raf-experiment-v2 {
    background-color: $product-warning-background;
    border-radius: 12px;

    @include at-least($screen-desktop) {
        border-radius: 12px;
    }


    .container-v2 {
        @include grid-blocks(6);
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        padding: 20px;
        align-items: center;
        flex-direction: column;

        @include at-least($screen-desktop) {
            flex-direction: row;
            padding: 0px 16px 0px 0px;
        }
        
        .icon-group {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .text-v2 {
                text-align: center;

                @include at-least($screen-desktop) {
                    text-align: left;
                    margin-top: 0;
                }

                h1 {
                    font-family: $handwritten-font-family;
                    font-size: $font-size-l;
                    padding: 0 0 0 1.5rem;
                    text-align: left;
                    margin-bottom: 0;
                    line-height: 34px;

                    @include at-least($screen-desktop) {
                        padding: 0 1rem;
                    }
                }

                a {
                    font-size: 16px;
                    color: $primary-colour;
                }
            }

            .widget-link-v2 {
                text-decoration: none;
                width: auto;
                display: inline-block;
                margin: 0 auto;

                @include at-least($screen-desktop) {
                    width: auto;
                    margin: 0;
                }

                .raf-v4-dog-widget-v2 {
                    border-radius: 12px;
                    width: 100%;
                    background-color: transparent;
                    position: relative;
                    margin-top: $spacing-xxs;

                    @include at-least($screen-desktop) {
                        margin-top: 0;
                    }

                    .dog-widget-img {
                        width: 110px;
                        margin: 0 auto;

                        @include at-least($screen-desktop) {
                            width: 90px;
                        }
                    }

                    .discount-box {
                        background-color: $secondary-03;
                        font-weight: 600;
                        font-size: 16px;
                        display: inline-block;
                        margin: 0;
                        padding: 4px;
                        position: absolute;
                        top: -10px;
                        left: 35%;
                        text-align: center;
                        width: auto;

                        @include at-least($screen-desktop) {
                            top: -20px;
                        }
                    }
                }
            }

        }
        
        .raf-link-v2 {
            width: 100%;
            display: inline-block;
            border-radius: 12px;
            overflow: hidden;
            // text-overflow: ellipsis;
            white-space: pre-wrap;

            @include at-least($screen-desktop) {
                width: auto;
            }
        }
    }
}
