/*
 * Containers
 */
$container-max-width: var(--container-max-width);
$container-default-width: var(--container-default-width);
$container-medium: var(--container-medium);

$grid-columns: 12;

/*
 * Screen sizes
 */

$screen-phone:               480px !default;
$screen-tablet:              800px !default;
$screen-desktop:             992px !default;
$screen-lg-desktop:          1200px !default;


// Extra small screen / phone
// Note: Deprecated $screen-xs and $screen-phone as of v3.0.1
$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$screen-tn-min: 320px;
$screen-tn-max: ($screen-xs-min - 1);

/*
 * Get rid of damned rounded corners everywhere
 */
$border-radius-base: 0;
$border-radius-small: 0;
$border-radius-large: 0;

/*
* Border radius from shared css
*/
$border-radius-xxs: var(--border-radius-xxs);
$border-radius-xs: var(--border-radius-xs);
$border-radius-s: var(--border-radius-s);

/*
 * Navbar
 */

$nav-max: $screen-desktop;
$nav-height: 90px;
$nav-height-small: 50px;


/*
 * Forms
 */
$input-border-radius: 0;


/* colours - primary */
$primary-colour: var(--primary-colour);
$primary-white: var(--primary-white);
$primary-cta: var(--primary-cta);
$primary-cta-hover: var(--primary-cta-hover);
$primary-cta-pressed: var(--primary-cta-pressed);
$secondary-colour: var(--secondary-colour);
$secondary-cta: var(--secondary-cta);
$secondary-cta-hover: var(--secondary-cta-hover);
$secondary-cta-pressed: var(--secondary-cta-pressed);
$secondary-cta-border: var(--secondary-cta-border);
$primary-text-link: var(--primary-text-link);
$primary-text-link-hover: var(--primary-text-link);
$primary-text-link-pressed: var(--primary-text-link-pressed);
$cta-disabled: var(--cta-disabled);
$cta-disabled-text: var(--cta-disabled-text);

/* colours - secondary */
$secondary-01: var(--primary-blueberry);
$secondary-02: var(--primary-blueberry);
$secondary-03: var(--primary-blueberry);
$secondary-04: var(--primary-blueberry);
$secondary-05: var(--primary-blueberry);
$secondary-06: var(--primary-blueberry);
$secondary-07: var(--primary-blueberry);
$secondary-08: var(--primary-blueberry);
$secondary-09: var(--primary-blueberry);
$secondary-10: var(--primary-blueberry);
$secondary-11: var(--primary-blueberry);
$secondary-12: var(--primary-blueberry);

/* colours - navy */
$navy-00: $primary-colour;
$navy-01: $primary-colour;
$navy-02: $primary-colour;
$navy-03: $primary-colour;
$navy-04: $primary-colour;

/* colours - pastels */
$pastel-lighter-frost: var(--pastel-lighter-frost);
$pastel-frost: var(--pastel-frost);
$pastel-darker-frost: var(--pastel-darker-frost);
$pastel-lighter-lavender: var(--pastel-lighter-lavender);
$pastel-lavender: var(--pastel-lavender);
$pastel-darker-lavender: var(--pastel-darker-lavender);
$pastel-lighter-ocean-mist: var(--pastel-lighter-ocean-mist);
$pastel-ocean-mist: var(--pastel-ocean-mist);
$pastel-darker-ocean-mist: var(--pastel-darker-ocean-mist);
$pastel-lighter-sage: var(--pastel-lighter-sage);
$pastel-sage: var(--pastel-sage);
$pastel-darker-sage: var(--pastel-darker-sage);
$pastel-lighter-sandstone: var(--pastel-lighter-sandstone);
$pastel-sandstone: var(--pastel-sandstone);
$pastel-darker-sandstone: var(--pastel-darker-sandstone);
$pastel-lighter-silver: var(--pastel-lighter-silver);
$pastel-silver: var(--pastel-silver);
$pastel-darker-silver: var(--pastel-darker-silver);
$pastel-lighter-beige: var(--pastel-lighter-beige);
$pastel-beige: var(--pastel-beige);
$pastel-darker-beige: var(--pastel-darker-beige);
$pastel-lighter-rose: var(--pastel-lighter-rose);
$pastel-rose: var(--pastel-rose);
$pastel-darker-rose: var(--pastel-darker-rose);

/* colours - food type */
$food-dry: var(--secondary-kibble-light);
$food-wet: var(--secondary-potato-light);
$food-other: var(--secondary-pear-light);

/* Rebrand Colours - primary */
$primary-black: var(--primary-black);
$primary-blueberry: var(--primary-blueberry);
$primary-blueberry-10: var(--primary-blueberry-10);
$primary-blueberry-30: var(--primary-blueberry-30);
$primary-blueberry-light: var(--primary-blueberry-light);
$primary-blueberry-light-10: var(--primary-blueberry-light-10);
$primary-blueberry-light-30: var(--primary-blueberry-light-30);
$secondary-potato: var(--secondary-potato);
$secondary-potato-light: var(--secondary-potato-light);
$secondary-potato-light-10: var(--secondary-potato-light-10);
$secondary-kibble: var(--secondary-kibble);
$secondary-kibble-light: var(--secondary-kibble-light);
$secondary-kibble-light-10: var(--secondary-kibble-light-10);
$secondary-pear: var(--secondary-pear);
$secondary-pear-light: var(--secondary-pear-light);
$secondary-pear-light-10: var(--secondary-pear-light-10);
$primary-grey: var(--primary-grey);
$primary-grey-dark: var(--primary-grey-dark);

/* Notifications */
$product-error: var(--error);
$product-error-background: var(--error-background);
$product-warning: var(--secondary-03);
$product-warning-background: var(--warn-background);
$product-success: var(--success-border);
$product-success-background: var(--success-background);
$product-info: var(--secondary-04);
$product-info-background: var(--primary-blueberry-light-10);

/* Product Defined */
$product-selected: var(--primary-blueberry);
$product-selected-darker: var(--primary-blueberry);
$product-selected-lighter: var(--primary-blueberry);

/* OLD Colours - do not use */
$primary-colour-10: rgba(3, 14, 45, 0.1);
$primary-colour-40: rgba(3, 14, 45, 0.4);
$secondary-03-10: rgba(255, 195, 62, 0.1); // Backgrounds, Gels, Labels, Highlights, Illustrations

/* Colour hacks */
$signup-background: '/static/images/tail-sand-bg.png';

/* Typographic sizes */
$base-font-size: var(--base-font-size); // this is now the value of 1rem
$font-size-xxs: var(--font-size-xxs);
$font-size-xs: var(--font-size-xs);
$font-size-s: var(--font-size-s);
$font-size-body: var(--font-size-body);
$font-size-m: var(--font-size-m);
$font-size-l: var(--font-size-l);
$font-size-xl: var(--font-size-xl);
$font-size-xxl: var(--font-size-xxl);
$font-size-title-s: var(--font-size-title-s);
$font-size-title-m: var(--font-size-title-m);
$font-size-title-l: var(--font-size-title-l);
$font-size-title-xl: var(--font-size-title-xl);
$font-weight-light: var(--font-weight-light); // 300
$font-weight-regular: var(--font-weight-regular); // 400
$font-weight-bold: var(--font-weight-bold); // 600

/* Spacing sizes */
$spacing-xxs: var(--spacing-xxs); // 8px
$spacing-xs: var(--spacing-xs); // 16px
$spacing-s: var(--spacing-s); // 24px
$spacing-m: var(--spacing-m); // 32px
$spacing-l: var(--spacing-l); // 40px
$spacing-xl: var(--spacing-xl); // 48px
$spacing-xxl: var(--spacing-xxl); // 56px
$spacing-xxxl: var(--spacing-xxxl); // 64px

$font-size-button: 1rem * 1.8; // 18px

/*
* SCSS variables, these are needed for SCSS functions such as lighten or darken
* as these functions require a hex colour and don't work with a CSS variable
*
* Convention to prefix with `scss
*/
// Containers
$scss-container-max-width: 1280px;
$scss-container-default-width: 1024px;
$scss-container-medium: 640px;

// Colours
$scss-primary-white: #fff;
$scss-primary-colour: #040e2b;
$scss-secondary-01: #ff8672;
$scss-secondary-02: #a49df3;
$scss-secondary-03: #ffc33e;
$scss-navy-01: #3e4c60;
$scss-navy-04: #dae3e8;
$scss-pastel-lighter-frost: #e8eff7;
$scss-pastel-darker-frost: #c7daef;
$scss-pastel-lighter-lavender: #f3f4ff;
$scss-pastel-darker-lavender: #dce0f9;

// Errors
$scss-product-error: #a91910;
